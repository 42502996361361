#Information {
  position: absolute;
  left: 5%;
  top: 70%;
  width: 90%;
  z-index: 1;
  background-color: white;
  border: 1px solid rgb(200,200,200);
  border-radius: 0.3em;
  color: gray;
  padding: 0.5em;
  font-family: 'Montserrat', sans-serif;

  box-shadow: 0 0 3px rgb(100,100,100);
}

.infoRemoveButton {
  position: absolute;
  right: 0;
  top: 0;

  background-color: transparent;
  border-width: 0;
  color: gray;
  padding: 0.3em;
  border-radius: 0.3em;
  font-weight: bold;
  font-size: 1.2em;
  transition: 0.2s;
  cursor: pointer;
}

.infoRemoveButton:hover {
  color: black;
}

.InfoPanel {
  position: relative;
  width: 100%;
  max-height: 40vh;
  overflow-y: auto;
}

.InfoPanel > h1 {
  font-size: 1.3em;
  color: rgb(100,100,100);
  margin-bottom: 0.5em;
  border-bottom: 1px solid rgb(200,200,200);
}

.InfoPanel > h2 {
  font-size: 1.1em;
  color: rgb(120, 120, 120);
  margin-bottom: 0.5em;
}

.InfoPanel > p {
  font-size: 0.8em;
  margin-bottom: 2em;
  line-height: 1.3em;
}

.ExamplesCont {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1em;
}