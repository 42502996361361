.GenericInterface {
  border: 2px solid rgb(200,200,200);
  margin-top: 0.3em;
  border-radius: 0.7em;
  background-color: white;
  position: relative;
  transition: 0.4s;
  width: 100%;
}
.GenericInterface:focus-within {
  border: 2px solid black;
}


.removeButton {
  position: absolute;
  left: 0;
  top: 0;

  background-color: transparent;
  border-width: 0;
  color: gray;
  padding: 0.3em;
  border-radius: 0.3em;
  font-weight: bold;
  font-size: 1.2em;
  transition: 0.2s;
  cursor: pointer;
}

.removeButton:hover {
  color: black;
}

.controlsAndSettingsToggleButton {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.integralControls {
  display: flex;
  width: calc(100% - 2em);
}

.settingsButtonCont {
  display: flex;
  align-items: center;
  color: gray;
  font-size: 1.5em;
  cursor: pointer;
  transition: 0.2s;
  width: 1.5em;
}
.settingsButtonCont:hover {
  color: black;
}

.settingsButton {
  transition: transform 0.4s ease-in-out;
  display: inline-flex; /* makes the span only go around settings */
}
.settingsButtonCont:hover .settingsButton {
  transform: rotate(90deg);
}