.ErrorUpdate {
  margin-top: 0.5em;
}


@keyframes buttonBounce {
  50% {
    border: 1px solid black;
    color: black;
  }
}


.updateGraphButton {
  padding: 0.5em;
  transition: 0.3s;
  background-color: transparent;
  border: 1px solid rgb(200,200,200);
  border-radius: 1em;
  color: gray;  
  font-family: 'Montserrat', sans-serif;

  animation: buttonBounce 1s;
  animation-iteration-count: infinite;
} 

.updateGraphButton:hover {
  border: 1px solid black;  
  color: black;
}

