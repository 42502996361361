* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
       box-sizing: border-box;
  /* font-family: sans-serif; */
}

html {
  /* overflow: hidden; */
}