#GraphAndControlsContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
} 

#GraphContainer {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 94%;
  background-color: rgb(250, 250, 250);
  padding-bottom: 2em;
  padding-right: 0.3em;
  padding-left: 0.3em;
}


#GraphsControlInfo {
  position: relative;
  border-bottom: 1px solid rgb(200,200,200);
  border-top: 1px solid rgb(200,200,200);
  box-shadow: 0 4px 5px -2px gray;
  display: flex;
  justify-content: center;
  padding: 0.4em;
  height: 6%;
  z-index: 1;
  background-color: rgb(248,248,248);
}


.GraphControlButton {
  background-color: white;
  border: 1px solid gray;
  border-radius: 0.5em;
  transition: 0.3s;
  color: gray;  
  padding: 0.2em;
  margin-right: 2%;
  margin-left: 2%;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
}

@media only screen and (max-width: 50em) {
  .GraphControlButton {
    font-size: 1.6vh;
  }
  #GraphsControlInfo {
    height: 20%;
  }
  #GraphContainer {
    height: 80%;
  }
}


.GraphControlButton:hover {
  border: 1px solid black;
  color: black;
}


#helpButton {
  border: 1px solid black;
  color: black;
}