.ExampleClickable {
  width: 8em;
  height: 5em;
  border: 1px solid rgb(200,200,200);
  box-shadow: 0 0 1px rgb(200,200,200);
  border-radius: 0.4em;
  margin: 0.2em;
  cursor: pointer;
  padding: 0.5em;
}

.ExampleClickable:hover {
  border: 1px solid black;
  color: black;
  box-shadow: 0 0 3px black;
}

.ExampleClickable h1 {
  font-size: 1em;
}