#LeftColumnContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgb(200,200,200);
}

#TitleContainer {
  position: relative;
  height: 5%;
  font-size: 1.6vh;
  font-family: 'Montserrat', sans-serif;
  color: rgb(70,70,70);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

#TitleContainer > button {
  background-color: rgb(255, 250, 250);
  border: 1px solid gray;
  border-radius: 0.5em;
  transition: 0.3s;
  color: gray;  
  padding: 0.2em;
  margin-right: 2%;
  margin-left: 2%;
  font-family: 'Montserrat', sans-serif;
  margin-top: 1%;
  font-size: 100%;
  cursor: pointer;
}

#TitleContainer > button:hover {
  background-color: rgb(255, 240, 240);
  border: 1px solid black;
  color: black;
}

@media only screen and (max-width: 50em) {
  #LeftColumnContainer {
    border-bottom: 1px solid rgb(200,200,200);
  }
  #TitleContainer {
    height: 15%;
    font-size: 1.5vh;
  }
  #AxisGraphConatiner {
    height: 85%;
  }
}

#AxisGraphContainer {
  display: flex;
  flex-direction: column;
  height: 95%;
}