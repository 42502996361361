.AxisInterface {
  border: 1px solid rgb(200,200,200);
  border-radius: 0.3em;
  padding: 0.3em;
  display: flex;
}

.AxisInterface button {
  border: 1px solid rgb(200,200,200);
  background-color: transparent;
  padding: 0.5em;
  border-radius: 0.5em;
  margin-left: 2em;
}

.AxisInterface button:hover {
  border: 1px solid black;
}
