.EquationControls {
  display: flex;
  flex-direction: column;
  margin-left: 10%;
  margin-top: 1em;
  margin-bottom: 1em;
  /* border: 1px solid darkmagenta; */
  width: 90%;
}

.Equation {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}

.mathquillInt {
  max-width: 70%;
  min-width: 30%;
}

.mathquillDel {
  max-width: 100%;
}

.controlDels {
  max-width: 30%;
  display: flex;
  flex-direction: column;
  margin-right: 2%;
}


