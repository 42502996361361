#MessageForm {
  border: 1px solid rgb(200,200,200);
  border-radius: 0.5em;
  background-color: white;
  box-shadow: 0 0 3px rgb(100,100,100);
  position: absolute;
  width: 80%;
  left: 10%;
  top: 50%;
  z-index: 2;
  padding: 1em;
  max-height: 40em;
  overflow-y: auto;
  font-size: 1em;
}

#MessageForm > p {
  margin-bottom: 1em;
}

.newInput {
  margin-bottom: 1em;
}
.newInput p {
  margin-bottom: 0.3em;
}

#MessageForm input {
  margin-right: 0.4em;
}

#MessageForm input[type=text] {
  border: none;
  border-bottom: 1px solid rgb(200,200,200);
  transition: 0.3s;
  width: 90%;
}

#MessageForm input[type=text]:focus {
  border: none;
  outline: none;
  border-bottom: 1px solid black;
}

#MessageForm button[type="submit"] {
  background-color: white;
  border: 1px solid gray;
  border-radius: 0.5em;
  transition: 0.3s;
  color: gray;  
  padding: 0.2em;
  margin-right: 2%;
  margin-left: 2%;
  font-family: 'Montserrat', sans-serif;
  margin-top: 1%;
  font-size: 100%;
  cursor: pointer;
}

#MessageForm button[type="submit"]:hover {
  border: 1px solid black;
  color: black;
}

.formRemoveButton {
  position: absolute;
  right: 0;
  top: 0;

  background-color: transparent;
  border-width: 0;
  color: gray;
  padding: 0.3em;
  border-radius: 0.3em;
  font-weight: bold;
  font-size: 1.7em;
  transition: 0.2s;
  cursor: pointer;
}

.formRemoveButton:hover {
  color: black;
}