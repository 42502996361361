.colorFFFFFF {
  border-color: rgb(240, 240, 240);
}

.colorFFFFFF:focus-within {
  border-color: rgb(161, 161, 161);
}

/* ------------------- */

.colorFF9AA2 {
  border-color: #FF9AA2;
}

.colorFF9AA2:focus-within {
  border-color: #FF0015;
}

/* ------------------- */

.colorFFB7B2 {
  border-color: #FFB7B2;
}

.colorFFB7B2:focus-within {
  border-color: #FF2819;
}

/* ------------------- */

.colorFFDAC1 {
  border-color: #FFDAC1;
}

.colorFFDAC1:focus-within {
  border-color: #FF7E28;
}

/* ------------------- */

.colorE2F0CB {
  border-color: #E2F0CB;
}

.colorE2F0CB:focus-within {
  border-color: #ABF03B;
}

/* ------------------- */

.colorB5EAD7 {
  border-color: #B5EAD7;
}

.colorB5EAD7:focus-within {
  border-color: #29EAA3;
}

/* ------------------- */

.colorADD8E6 {
  border-color: #ADD8E6;
}

.colorADD8E6:focus-within {
  border-color: #23B5E6;
}

/* ------------------- */

.colorC7CEEA {
  border-color: #C7CEEA;
}

.colorC7CEEA:focus-within {
  border-color: #3B5EEA;
}

/* ------------------- */
