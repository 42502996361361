.SettingsControls {
  margin-left: 10%;
  margin-top: 1.4em;
  margin-bottom: 1em;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.SettingsControls .ChooseColors {
  display: flex;
  flex-direction: row;
  width: 40%;
  flex-wrap: wrap;
}

.SettingsControls .OtherSettings {
  width: 60%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
}

.SettingsControls .singleColor {
  border: 1px solid rgb(200,200,200);
  width: 1.5em;
  height: 1.5em;
  margin-right: 0.4em;
  margin-bottom: 0.4em;
  border-radius: 0.2em;
}

.SettingsControls .singleColor:hover {
  border: 1px solid black;
}

.SettingsControls .OtherSettings .OtherSettingsSett {
  margin-bottom: 0.4em;
}

.SettingsControls .OtherSettings .OtherSettingsSett button {
  border: 1px solid rgb(200,200,200);
  background-color: transparent;
  padding: 0.2em; 
  border-radius: 0.4em;
  transition: 0.4s;
  font-family: 'Montserrat', sans-serif;
  color: gray;
}
.SettingsControls .OtherSettings .OtherSettingsSett button:hover {
  border: 1px solid black;
  color: black;
}